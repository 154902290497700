import React from "react"
import {FormattedMessage, injectIntl} from "gatsby-plugin-react-intl"

class PassFooter extends React.Component {

  componentDidMount() {

  }

  render() {

    let barcode = this.props.pass.data.barcode;
    if (this.props.pass.data?.barcodes?.length) {
      barcode = this.props.pass.data.barcodes[0];
    }

    let barcodeFormat = null;
    if (barcode?.format) {
      let f = barcode.format;
      if (f === "PKBarcodeFormatQR") {
        barcodeFormat = "qr";
      } else if (f === "PKBarcodeFormatPDF417") {
        barcodeFormat = "pdf417";
      } else if (f === "PKBarcodeFormatCode128") {
        barcodeFormat = "code128";
      } else if (f === "PKBarcodeFormatAztec") {
        barcodeFormat = "aztec";
      }
    }

    let host = "https://push.notifica.re";
    if (process.env.GATSBY_ACTIVE_ENV === 'test') {
      host = "https://push-test.notifica.re";
    }

    let isExpired = false;
    if (this.props.pass.data.expirationDate) {
      let expiration = new Date(this.props.pass.data.expirationDate);
      if (expiration && expiration.getTime() < new Date().getTime()) {
        isExpired = true;
      }
    }

    const barcodeImage = () => {
      if (isExpired) {
        return <img src={`${host}/pass/barcode/${barcodeFormat}/${barcode.message}`} alt={``} style={{opacity: ".3"}} />;
      } else {
        return <img src={`${host}/pass/barcode/${barcodeFormat}/${barcode.message}`} alt={``} />;
      }
    }

    const barcodeMessage = () => {
      if (isExpired) {
        return <div className={`barcode-text`} style={{opacity: ".3"}}><FormattedMessage id="shared.expired" /></div>;
      } else {
        if (barcode.altText) {
          return <div className={`barcode-text`}>{barcode.message}</div>;
        } else {
          return "";
        }
      }
    }

    return (
      <div className={`pass-footer`}>
        <div className="barcode-area">
          {this.props.pass.data.footer &&
          <div className={`footer-image`}><img src={this.props.pass.data.footer} alt="" /></div>
          }
          {barcodeFormat &&
          <div className={`barcode ${barcodeFormat}`}>
            <div className={`barcode-image`}>
              {barcodeImage()}
            </div>
            {barcodeMessage()}
          </div>
          }
        </div>
        {this.props.pass.data.associatedStoreIdentifiers &&
        <div className={`icon`}>
          <img className={`icon-image`} src={this.props.pass.data.icon} alt={``} />
        </div>
        }
      </div>
    )
  }

}

export default injectIntl(PassFooter)