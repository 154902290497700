import React from "react"
import { injectIntl } from "gatsby-plugin-react-intl"
import PassField from "../misc/pass-field"

class PassStyleCard extends React.Component {

  componentDidMount() {

  }

  render() {
    let primaryField = null,
      secondaryFields = this.props.pass.data.secondaryFields || [],
      auxiliaryFields = this.props.pass.data.auxiliaryFields || [],
      fields = secondaryFields.concat(auxiliaryFields);

    if (this.props.pass.data.primaryFields && this.props.pass.data.primaryFields.length > 0) {
      primaryField = this.props.pass.data.primaryFields[0];
    }

    let mixedFields = fields.slice(0, 4).map(f => {
      return f;
    })


    return (
      <div className={`pass-style-card`}>
        {this.props.pass.data.strip &&
        <div className={`strip`}>
          <img src={this.props.pass.data.strip} alt={``} />
        </div>
        }
        <div className="primary-fields">
          {primaryField &&
            <PassField field={primaryField} pass={this.props.pass} />
          }
        </div>
        <div className="mixed-fields">
          {mixedFields.map((field, i) => {
            return (
              <PassField key={i} field={field} pass={this.props.pass} />
            )
          })}
        </div>
      </div>
    )
  }

}

export default injectIntl(PassStyleCard)
