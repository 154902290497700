import React from "react"
import { injectIntl } from "gatsby-plugin-react-intl"
import PassField from "../misc/pass-field"

class PassStyleTicket extends React.Component {

  componentDidMount() {

  }

  render() {
    let primaryField = null,
      sFields = this.props.pass.data.secondaryFields || [],
      aFields = this.props.pass.data.auxiliaryFields || [];

    if (this.props.pass.data.primaryFields && this.props.pass.data.primaryFields.length > 0) {
      primaryField = this.props.pass.data.primaryFields[0];
    }

    let secondaryFields = sFields.slice(0, 4).map(f => {
      return f;
    })

    let auxiliaryFields = aFields.slice(0, 4).map(f => {
      return f;
    })

    let bgClass = "", withBgImage = false;
    if (this.props.pass.data.background){
      bgClass = "withBgImage";
      withBgImage = true;
    }


    return (
      <div className={`pass-style-ticket ${bgClass}`}>
        {!withBgImage &&
        <div className={`strip`}>
          <img src={this.props.pass.data.strip} alt={``} />
        </div>
        }
        <div className="primary-fields">
          {primaryField &&
          <PassField field={primaryField} pass={this.props.pass} />
          }
        </div>
        {withBgImage &&
        <div className={`thumbnail`}>
          <img src={this.props.pass.data.thumbnail} alt={``} />
        </div>
        }
        <div className="secondary-fields">
          {secondaryFields.map((field, i) => {
            return (
              <PassField key={i} field={field} pass={this.props.pass} />
            )
          })}
        </div>
        <div className="auxiliary-fields">
          {auxiliaryFields.map((field, i) => {
            return (
              <PassField key={i} field={field} pass={this.props.pass} />
            )
          })}
        </div>
      </div>
    )
  }

}

export default injectIntl(PassStyleTicket)

