import React from "react"
import { injectIntl } from "gatsby-plugin-react-intl"
import PassField from "../misc/pass-field"

class PassStyleBoarding extends React.Component {

  componentDidMount() {

  }

  render() {
    let toField = null,
      fromField = null,
      sFields = this.props.pass.data.secondaryFields || [],
      aFields = this.props.pass.data.auxiliaryFields || [];


    if (this.props.pass.data.primaryFields && this.props.pass.data.primaryFields.length > 1) {
      fromField = this.props.pass.data.primaryFields[0];
      toField = this.props.pass.data.primaryFields[1];
    }

    let secondaryFields = sFields.slice(0, 4).map(f => {
      return f;
    })

    let auxiliaryFields = aFields.slice(0, 4).map(f => {
      return f;
    })

    const transitTypeComponent = () => {
      if (this.props.pass.data.transitType === "PKTransitTypeTrain") {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="27.000000pt" height="27.000000pt" viewBox="0 0 74.000000 74.000000" preserveAspectRatio="xMidYMid meet"> <g transform="translate(0.000000,74.000000) scale(0.100000,-0.100000)" fill={this.props.pass.data.foregroundColor} stroke="none"> <path d="M199 697 c-34 -26 -39 -55 -39 -217 0 -162 5 -191 39 -217 22 -17 330 -17 352 0 34 26 39 55 39 221 0 162 0 165 -26 195 l-26 31 -161 0 c-112 -1 -167 -4 -178 -13z m320 -121 c7 -9 11 -42 9 -88 l-3 -73 -139 -3 c-93 -2 -143 1 -152 9 -18 15 -19 140 -2 157 18 18 272 17 287 -2z"></path> <path d="M201 150 c-70 -109 -71 -112 -30 -108 27 2 39 11 58 41 l24 37 121 0 121 0 25 -40 c21 -33 30 -40 57 -40 18 0 33 3 33 6 0 4 -26 47 -57 95 -53 83 -59 89 -91 89 -34 0 -34 0 -19 -24 9 -13 13 -26 10 -30 -8 -7 -163 -8 -163 -1 0 3 7 17 16 30 16 25 16 25 -19 25 -33 0 -38 -5 -86 -80z"></path> </g></svg>
        )
      } else if (this.props.pass.data.transitType === "PKTransitTypeBoat") {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="27.000000pt" height="27.000000pt" viewBox="0 0 74.000000 74.000000" preserveAspectRatio="xMidYMid meet"> <g transform="translate(0.000000,74.000000) scale(0.100000,-0.100000)" fill={this.props.pass.data.foregroundColor} stroke="none"> <path d="M270 660 c0 -11 -7 -20 -15 -20 -10 0 -15 -10 -15 -30 0 -25 -4 -30 -23 -30 -23 0 -25 -6 -39 -105 -9 -68 -11 -105 -4 -105 5 0 50 23 98 50 48 28 95 50 103 50 8 0 55 -22 103 -50 48 -27 93 -50 98 -50 7 0 5 37 -4 105 -14 99 -16 105 -39 105 -19 0 -23 5 -23 30 0 20 -5 30 -15 30 -8 0 -15 9 -15 20 0 15 -7 20 -25 20 -18 0 -25 -5 -25 -20 0 -18 -7 -20 -55 -20 -48 0 -55 2 -55 20 0 15 -7 20 -25 20 -18 0 -25 -5 -25 -20z"></path> <path d="M210 353 c-74 -42 -137 -78 -140 -80 -6 -5 49 -163 63 -180 14 -18 470 -18 484 0 14 17 69 175 63 180 -8 7 -278 157 -283 157 -3 0 -9 -57 -13 -127 l-8 -128 -11 122 c-5 67 -12 124 -15 126 -3 3 -66 -29 -140 -70z"></path> </g></svg>
        )
      } else if (this.props.pass.data.transitType === "PKTransitTypeBus") {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="27.000000pt" height="27.000000pt" viewBox="0 0 74.000000 74.000000" preserveAspectRatio="xMidYMid meet"> <g transform="translate(0.000000,74.000000) scale(0.100000,-0.100000)" fill={this.props.pass.data.foregroundColor} stroke="none"> <path d="M206 709 c-54 -13 -104 -46 -121 -79 -22 -42 -22 -497 0 -526 8 -10 15 -29 15 -40 0 -30 23 -46 62 -42 28 3 34 8 38 33 l5 30 160 3 161 2 9 -32 c8 -28 15 -34 43 -36 39 -4 62 12 62 42 0 11 7 30 15 40 12 16 15 64 15 260 0 278 -2 285 -86 326 -44 21 -65 24 -194 26 -80 2 -163 -1 -184 -7z m326 -64 c3 -3 3 -17 0 -30 l-4 -25 -159 0 c-133 0 -161 3 -165 15 -4 8 -4 22 0 30 4 12 32 15 164 15 87 0 161 -2 164 -5z m57 -109 c8 -9 11 -43 9 -93 l-3 -78 -227 -3 -228 -2 0 83 c0 51 5 88 12 95 19 19 422 17 437 -2z"></path> </g> </svg>
        )
      } else if (this.props.pass.data.transitType === "PKTransitTypeGeneric") {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="27.000000pt" height="27.000000pt" viewBox="0 0 74.000000 74.000000" preserveAspectRatio="xMidYMid meet"> <g transform="translate(0.000000,74.000000) scale(0.100000,-0.100000)" fill={this.props.pass.data.foregroundColor} stroke="none"> <path d="M410 485 l64 -65 -180 -2 -179 -3 0 -35 0 -35 179 -3 180 -2 -64 -65 -64 -65 49 0 c49 1 52 3 138 84 l87 83 -87 86 c-87 85 -89 87 -138 87 l-49 0 64 -65z"></path> </g></svg>
        )
      } else {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="27.000000pt" height="27.000000pt" viewBox="0 0 74.000000 74.000000" preserveAspectRatio="xMidYMid meet"><g transform="translate(0.000000,74.000000) scale(0.100000,-0.100000)" fill={this.props.pass.data.foregroundColor} stroke="none"> <path d="M253 708 c3 -13 26 -72 51 -133 28 -66 45 -122 44 -140 -3 -26 -8 -31 -43 -37 -22 -4 -70 -7 -106 -7 -64 -1 -68 0 -93 33 -39 52 -56 66 -76 66 -17 0 -17 -3 -3 -57 11 -41 12 -58 3 -61 -8 -3 -8 -8 0 -17 8 -10 7 -27 -4 -66 -9 -29 -14 -54 -12 -56 11 -11 59 22 88 61 l33 45 75 -5 c41 -3 90 -7 109 -10 33 -5 33 -6 29 -47 -3 -23 -28 -95 -55 -159 l-50 -118 28 0 c21 0 35 10 56 39 22 31 36 41 62 43 39 3 54 27 23 35 -21 5 -21 5 1 39 17 27 29 34 54 34 37 0 54 19 24 27 -12 3 -21 7 -21 10 0 2 15 23 33 47 l32 44 94 10 c52 6 99 16 105 22 19 19 -20 38 -92 45 -37 3 -76 7 -87 8 -10 1 -34 22 -53 46 -33 44 -33 45 -13 53 32 12 25 26 -16 34 -23 4 -47 17 -60 33 -28 33 -28 41 -3 41 11 0 20 7 20 15 0 10 -10 15 -34 15 -28 0 -40 8 -70 45 -41 50 -83 63 -73 23z"></path> </g></svg>
        )
      }
    }


    return (
      <div className={`pass-style-boarding`}>
        {this.props.pass.data.strip &&
        <div className={`strip`}>
          <img src={this.props.pass.data.strip} alt={``} />
        </div>
        }
        <div className={`primary-fields`}>
          <div className={`from`}>
            {fromField &&
            <PassField field={fromField} pass={this.props.pass} />
            }
          </div>
          <div className={`transit-type`}>
            {transitTypeComponent()}
          </div>
          <div className={`to`}>
            {toField &&
            <PassField field={toField} pass={this.props.pass} />
            }
          </div>
        </div>
        <div className={`secondary-fields`}>
          {secondaryFields.map((field, i) => {
            return (
              <PassField key={i} field={field} pass={this.props.pass} />
            )
          })}
        </div>
        <div className={`auxiliary-fields`}>
          {auxiliaryFields.map((field, i) => {
            return (
              <PassField key={i} field={field} pass={this.props.pass} />
            )
          })}
        </div>
      </div>
    )
  }

}

export default injectIntl(PassStyleBoarding)
