import React from "react"
import '@formatjs/intl-locale/polyfill'
import '@formatjs/intl-getcanonicallocales/polyfill'
import { injectIntl } from "gatsby-plugin-react-intl"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PassHandler from "../components/pass"

const IndexPage = ({ intl }) => (
  <>
    <Layout>
      <Seo lang={intl.locale}
           title={intl.formatMessage({ id: "title" })}
           description={intl.formatMessage({ id: "description" })}
      />
      <PassHandler />
    </Layout>
  </>
)

export default injectIntl(IndexPage)
