import React from "react"
import { injectIntl } from "gatsby-plugin-react-intl"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Moment from "react-moment";

class PassEntry extends React.Component {

  componentDidMount() {

  }

  render() {

    let barcodeFormat = null;
    if (this.props.pass.barcode?.format) {
      let f = this.props.pass.barcode.format;
      if (f === "PKBarcodeFormatQR") {
        barcodeFormat = "qr";
      } else if (f === "PKBarcodeFormatPDF417") {
        barcodeFormat = "pdf417";
      } else if (f === "PKBarcodeFormatCode128") {
        barcodeFormat = "code128";
      } else if (f === "PKBarcodeFormatAztec") {
        barcodeFormat = "aztec";
      }
    }

    let host = "https://push.notifica.re";
    if (process.env.GATSBY_ACTIVE_ENV === 'test') {
      host = "https://push-test.notifica.re";
    }

    return (
      <div className={`pass-entry`}>
        <a href={`/#/${this.props.pass.application}/${this.props.pass.barcode.message}`} aria-label={`Show`}>
          <div className={`pass-barcode`}>
            <img src={`${host}/pass/barcode/${barcodeFormat}/${this.props.pass.barcode.message}`} alt={``} />
          </div>
          <div className={`pass-info`}>
            <div className={`pass-description`}>{this.props.pass.description}</div>
            <div className={`pass-date`}><Moment date={this.props.pass.date} format={`MMMM Do, YYYY`} /></div>
          </div>
          <FontAwesomeIcon size={`lg`} icon="angle-right" />
        </a>
      </div>
    )
  }

}

export default injectIntl(PassEntry)