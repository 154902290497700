import React from "react"
import html2canvas from 'html2canvas'
import { fetchPass, fetchPassTemplate, fetchAppStoreInfo, fetchSaveLinks } from "../request"
import Moment from 'react-moment';
import { injectIntl, FormattedMessage } from "gatsby-plugin-react-intl"
import { Spinner, Container } from "react-bootstrap"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PassStyleBoarding from "./pass-styles/boarding"
import PassStyleCoupon from "./pass-styles/coupon"
import PassStyleCard from "./pass-styles/card"
import PassStyleTicket from "./pass-styles/ticket"
import PassStyleGeneric from "./pass-styles/generic"
import PassHeader from "./misc/pass-header"
import PassFooter from "./misc/pass-footer"
import PassBackField from "./misc/pass-back-field"
import PassEntry from "./misc/pass-entry"
import iconApple from "../images/apple-add-wallet-en.svg"
import iconGoogle from "../images/google-add-wallet-en.svg"
import Switch from "react-switch"

class PassHandler extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      isLoading: true,
      showPasses: false,
      passes: [],
      pass: null,
      showFront: true,
      screenshot: null,
      appStoreInfo: null,
      ua: null,
      isWebPushSupported: false,
      notificare: null,
      isNotificationsEnabled: false,
      canShare: true,
      appleSaveLink: null,
      googleSaveLink: null
    }
  }

  componentDidMount() {

    if (typeof window !== 'undefined') {
      if (!localStorage.getItem("notificareWebPasses")) {
        localStorage.setItem("notificareWebPasses", JSON.stringify({passes: []}));
      }

      let notificareWebPassesString = localStorage.getItem("notificareWebPasses"),
          notificareWebPasses = JSON.parse(notificareWebPassesString);
      this.setState({
        passes: notificareWebPasses.passes
      });
    }

    this.handleHash();
    window.addEventListener( "hashchange", this.handleHash.bind(this));

    //UA Parser
    let uaScript = document.createElement('script');
    uaScript.id = 'uaJSLib';
    uaScript.type = 'text/javascript';
    uaScript.src = "https://cdn.jsdelivr.net/npm/ua-parser-js@1.0.37/dist/ua-parser.min.js";

    if (!document.getElementById("uaJSLib")) {
      document.body.appendChild(uaScript);
      uaScript.onload = () => {
        this.setState({
          ua: new window.UAParser()
        })
      }
    } else {
      this.setState({
        ua: new window.UAParser()
      })
    }

    /// Notificare
    let notificareScript = document.createElement('script');
    notificareScript.id = 'notificareJSLib';
    notificareScript.type = 'text/javascript';
    notificareScript.src = "https://cdn.notifica.re/libs/html5/v2/latest/notificare.min.js";

    if (!document.getElementById("notificareJSLib")) {
      document.body.appendChild(notificareScript);
      notificareScript.onload = () => {
        this.initNotificare();
      }
    } else {
      this.initNotificare();
    }

  }

  componentWillUnmount() {
    window.removeEventListener("hashchange", this.handleHash.bind(this), false);
  }

  initNotificare = () => {

    let notificare = new window.Notificare({
      "appHost": (process.env.GATSBY_ACTIVE_ENV === 'test') ? "https://pass-test.notifica.re" : "https://pass.notifica.re",
      "appVersion": "2.0.0",
      "appKey": "43b7483b040a71532c33bcae629fcb9fa8235d7eb39776c4c5c02b558a5e86c2",
      "appSecret": "a43fa6a065ccf7b3299ccce1278a10222285df8622d18daa1589d625e14817c0",
      "soundsDir": "/sounds/",
      "serviceWorker": "/sw.js",
      "geolocationOptions": {
        "timeout": 60000,
        "enableHighAccuracy": true,
        "maximumAge": 100000
      }
    });

    this.setState({
      notificare: notificare,
      isWebPushSupported: false //notificare.isWebPushSupported() ///temporarily disabled
    });

    notificare.launch();

    notificare.onReady = () => {
      this.setState({
        isNotificationsEnabled: notificare.isWebPushEnabled()
      });

      if (this.state.pass) {
        let tags = [];
        tags.push('tag_serial_' + this.state.pass.serial); //serial
        tags.push('tag_barcode_' + this.state.pass.barcode); //barcode
        tags.push('tag_app_' + this.state.pass.application._id); //application

        notificare.addTags(tags).then(() => {

          notificare.logCustomEvent("PassView", {
            pass_application: this.state.pass.application._id,
            pass_serial: this.state.pass.serial,
            pass_barcode: this.state.pass.barcode,
          });

        });
      }

    }

    notificare.didRegisterDevice = () => {
      this.setState({
        isNotificationsEnabled: notificare.isWebPushEnabled()
      });
    }
  }

  handleHash(){
    let hash = window.location?.hash;
    let hashComps = hash.split("/");

    if (hashComps && hashComps.length === 3){

      this.setState({
        isLoading: true
      })

      let app = hashComps[1], barcode = hashComps[2]

      fetchPass({
        app: app,
        barcode: barcode
      }).then((r) => {
        if (r?.data?.pass) {
          let pass = r.data.pass;
          this.setState({
            isLoading: false,
            pass: pass,
            showFront: true
          })
          this.handlePageAttrs(pass);
          this.handleScreenshot();
          this.handleAppStoreInfo(pass);
          this.handleShareOptions(pass);
          this.handleSaveLinks(pass);
        } else {
          this.setState({
            isLoading: false,
            pass: null,
            showPasses: false
          })
        }
      }).catch(()=> {
        this.setState({
          isLoading: false,
          pass: null,
          showPasses: false
        })
      });

    } else if (hashComps && hashComps.length === 4) {

      this.setState({
        isLoading: true
      })

      let app = hashComps[2], passbook = hashComps[3]

      fetchPassTemplate({
        app: app,
        passbook: passbook
      }).then((r) => {
        if (r?.data?.passbook) {
          let pass = r.data.passbook;
          pass.passbook = {
            _id: pass._id,
            passStyle: pass.passStyle
          }
          pass.date = new Date();
          this.setState({
            isLoading: false,
            pass: pass,
            showFront: true
          })
          this.handlePageAttrs(pass);
          this.handleScreenshot();
          this.handleAppStoreInfo(pass);
          this.handleShareOptions(pass);
        } else {
          this.setState({
            isLoading: false,
            pass: null,
            showPasses: false
          })
        }
      }).catch(()=> {
        this.setState({
          isLoading: false,
          pass: null,
          showPasses: false
        })
      });

    } else if (hashComps && hashComps.length < 3) {
      this.setState({
        isLoading: false,
        pass: null,
        showPasses: true
      })
    } else {
      this.setState({
        isLoading: false,
        pass: null,
        showPasses: false
      })
    }
  }

  handlePageAttrs(pass){
    document.title = pass.data.description;
    let links = document.querySelectorAll("link[rel='icon']");
    links.forEach((link) => {
      link.setAttribute('href', pass.data.icon);
    });
  }

  handleScreenshot(){
    let source = document.getElementById("passScreenshotArea");
    html2canvas(source, {
      allowTaint: true
    }).then((r) => {
      r.style.width = "107px";
      r.style.height = "167px";
      document.getElementById('passScreenshot').appendChild(r);
    });
  }

  handleAppStoreInfo(pass){

    if (pass.data.associatedStoreIdentifiers &&
        pass.data.associatedStoreIdentifiers.length > 0 &&
        (this.state.ua?.getOS() && this.state.ua?.getOS()?.name === "iOS")) {

      fetchAppStoreInfo({
        x: new Date().getTime(),
        country: 'NL',
        id: pass.data.associatedStoreIdentifiers[0]
      }).then((r) => {
        if (r?.data?.results && r?.data?.results.length > 0) {
          this.setState({
            appStoreInfo: r.data.results[0]
          });
        } else {
          this.setState({
            appStoreInfo: null
          });
        }
      }).catch(() => {
        this.setState({
          appStoreInfo: null
        });
      });

    } else {
      this.setState({
        appStoreInfo: null
      });
    }
  }

  handleShareOptions(pass){
    if (pass.data.sharingProhibited || !navigator.canShare) {
      this.setState({
        canShare: false
      });
    }
  }

  handleOptionsButton(e) {
    e.preventDefault();
    this.setState({
      showFront: false
    })
  }

  handleBackButton(e) {
    e.preventDefault();
    this.setState({
      showFront: true
    })
  }

  async handleShareButton(e) {
    e.preventDefault();
    try {
      await navigator.share({
        title: this.state.pass.data.description,
        text: "Checkout this digital pass",
        url: window.location.href
      })
    } catch(e) {
      console.log(e);
    }
  }

  handleNotificationsChange = (e) => {
    this.setState({ isNotificationsEnabled: e });
    if (e) {
      this.state.notificare.registerForNotifications();
    } else {
      this.state.notificare.unregisterForNotifications();
    }
  }

  handleSaveLinks(pass){
    if (pass.version === 2) {
      fetchSaveLinks({
        serial: pass.serial
      }).then((r) => {
        if (r && r.data && r.data.saveLinks) {
          let links = r.data.saveLinks
          this.setState({
            googleSaveLink: links.googlePay || null,
            appleSaveLink: links.appleWallet || null
          })
        }
      }).catch((e) => {
        console.log(e);
      });
    }
  }

  handleSaveButton(e){
    e.preventDefault();
    if (typeof window !== 'undefined') {
      let notificareWebPassesString = localStorage.getItem("notificareWebPasses"),
          notificareWebPasses = JSON.parse(notificareWebPassesString);
      let pass = this.state.pass;
      if (!this.checkIfPassIsAdded(pass)) {
        notificareWebPasses.passes.push({
          id: pass._id,
          date: pass.date,
          application: pass.application._id,
          serial: pass.serial,
          barcode: pass.data.barcode,
          description: pass.data.description
        });
        localStorage.setItem("notificareWebPasses", JSON.stringify(notificareWebPasses));
        window.location.reload();
      }
    }
  }

  handleRemoveButton(e) {
    e.preventDefault();
    if (typeof window !== 'undefined') {
      let notificareWebPassesString = localStorage.getItem("notificareWebPasses"),
          notificareWebPasses = JSON.parse(notificareWebPassesString),
          passes = [];

      notificareWebPasses.passes.forEach((p) => {
        if (p.id !== this.state.pass._id) {
          passes.push(p);
        }
      });

      localStorage.setItem("notificareWebPasses", JSON.stringify({passes: passes}));
      window.location.reload();
    }
  }

  checkIfPassIsAdded(pass){
    let output = false;
    if (typeof window !== 'undefined') {
      let notificareWebPassesString = localStorage.getItem("notificareWebPasses"),
          notificareWebPasses = JSON.parse(notificareWebPassesString);
      if (notificareWebPasses.passes && notificareWebPasses.passes.length > 0) {
        notificareWebPasses.passes.forEach((p) => {
          if (p.id === pass._id) {
            output = true;
          }
        });
      }
    }
    return output;
  }

  render() {

    if (this.state.isLoading) {
      return (
        <Container>
          <div className={`pass loading`}>
            <Spinner animation="border" variant="light" />
          </div>
        </Container>
      )
    } else {
      if (this.state.pass) {

        let isExpired = false;
        if (this.state.pass.data.expirationDate) {
          let expiration = new Date(this.state.pass.data.expirationDate);
          if (expiration && expiration.getTime() < new Date().getTime()) {
            isExpired = true;
          }
        }

        let style = this.state.pass.passbook.passStyle;
        const passComponent = () => {
          if (style === "boarding") {
            return <PassStyleBoarding pass={this.state.pass} />;
          } else if (style === "card") {
            return <PassStyleCard pass={this.state.pass} />;
          } else if (style === "coupon") {
            return <PassStyleCoupon pass={this.state.pass} />;
          } else if (style === "ticket") {
            return <PassStyleTicket pass={this.state.pass} />;
          } else if (style === "generic") {
            return <PassStyleGeneric pass={this.state.pass} />;
          } else {
            return <PassStyleCard pass={this.state.pass} />;
          }
        }

        const downloadButtonComponent = () => {
          let host = "https://push.notifica.re";
          if (process.env.GATSBY_ACTIVE_ENV === 'test') {
            host = "https://push-test.notifica.re";
          }

          if (!isExpired) {
            if (this.state.ua?.getOS() && (this.state.ua?.getOS()?.name !== "iOS" && this.state.ua?.getOS()?.name !== "Android")) {
              if (!this.checkIfPassIsAdded(this.state.pass)) {
                return (
                    <div className={`download-button web`}>
                      <a href={`/`} onClick={this.handleSaveButton.bind(this)} aria-label={`Save`}><FormattedMessage id="shared.save" /></a>
                    </div>
                )
              } else {
                return ("")
              }
            } else {
              if (this.state.pass.version === 2) {

                if (this.state.ua?.getOS() && this.state.ua?.getOS()?.name === "iOS") {
                  return (
                      <div className={`download-button apple`}>
                        <a href={this.state.appleSaveLink} aria-label={`Add`}><img src={iconApple} alt={``} /></a>
                      </div>
                  )
                } else if (this.state.ua?.getOS() && this.state.ua?.getOS()?.name === "Android" && this.state.googleSaveLink) {
                  return (
                      <div className={`download-button google-pay`}>
                        <a href={this.state.googleSaveLink} aria-label={`Save`}><img src={iconGoogle} alt={``} /></a>
                      </div>
                  )
                } else {
                  return ("")
                }
              } else {
                if (this.state.ua?.getOS() && this.state.ua?.getOS()?.name === "iOS") {
                  return (
                      <div className={`download-button apple`}>
                        <a href={`${host}/pass/pkpass/${this.state.pass.serial}`} aria-label={`Add`}><img src={iconApple} alt={``} /></a>
                      </div>
                  )
                } else {
                  return ("")
                }
              }
            }
          } else {
            return ("")
          }

        }

        const artifactComponent = () => {
          if (this.state.pass.passbook.passStyle === "boarding") {
            return (
              <>
                <div className={`half-circle left`}></div>
                <div className={`half-circle right`}></div>
              </>
            )
          } else if (this.state.pass.passbook.passStyle === "coupon") {
            return (
              <>
                <div className={`line`}>
                  {[...Array(64)].map((x, i) =>
                    <div key={i + 1} className={`half-circle bottom`}></div>
                  )}
                </div>
                <div className={`line in-bottom`}>
                  {[...Array(64)].map((x, i) =>
                    <div key={i + 1} className={`half-circle top`}></div>
                  )}
                </div>
              </>
            )
          } else if (this.state.pass.passbook.passStyle === "ticket") {
            return (
              <div className={`half-circle`}></div>
            )
          } else {
            return ("")
          }
        }

        return (
          <Container>
            <div className={`pass`}>
              <div className={`pass-area`} style={{display: (this.state.showFront) ? "block": "none"}}>
                <div className={`pass-nav`}>
                  <div className={`title`}>
                    {this.state.pass.data.description}
                  </div>
                  <div className={`options`}>
                    <a href={`/`} onClick={this.handleOptionsButton.bind(this)} aria-label={`options`} className={`button`}><FontAwesomeIcon icon="ellipsis-h" size="xs" /></a>
                  </div>
                </div>
                <div id={`passScreenshotArea`}>
                  {this.state.pass.data.background &&
                  <div className="pass-bg">
                    <div className="bg" style={{background: `url(${this.state.pass.data.background}) no-repeat`}}></div>
                  </div>
                  }
                  <div className={`pass-style-artifact ${this.state.pass.passbook.passStyle}`}>
                    {artifactComponent()}
                  </div>
                  <div id={`passFront`} className={`pass-front style-${this.state.pass.passbook.passStyle}`} style={{background: this.state.pass.data.background ? 'transparent' : this.state.pass.data.backgroundColor}}>
                    <PassHeader pass={this.state.pass} />
                    {passComponent()}
                    <PassFooter pass={this.state.pass} />
                  </div>
                </div>
                {downloadButtonComponent()}
              </div>
              <div className={`pass-area`} style={{display: (this.state.showFront) ? "none": "block"}}>
                <div className={`pass-back`}>
                  <div className={`pass-nav`}>
                    <div className={`back`}>
                      <a href={`/`} onClick={this.handleBackButton.bind(this)} aria-label={`back`} className={`button`}><FontAwesomeIcon icon="chevron-left" fixedWidth={true} /></a>
                    </div>
                    {this.state.canShare &&
                    <div className={`share`}>
                      <a href={`/`} onClick={this.handleShareButton.bind(this)} aria-label={`share`}
                         className={`button`}><FontAwesomeIcon icon="external-link-alt" fixedWidth={true} /></a>
                    </div>
                    }
                  </div>
                  <div id={`passScreenshot`} className={`screenshot`}></div>
                  <div className={`title`}>{this.state.pass.data.description}</div>
                  <div className={`update-time`}><FormattedMessage id="shared.updated" /> <Moment fromNow>{this.state.pass.date}</Moment></div>
                  {this.state.appStoreInfo &&
                  <div className={`app-store-info`}>
                    <div className={`icon`}>
                      <img src={this.state.appStoreInfo.artworkUrl60} alt={``} />
                    </div>
                    <div className={`app-info`}>
                      <div className={`name`}>{this.state.appStoreInfo.trackName}</div>
                      <div className={`company`}>{this.state.appStoreInfo.artistName}</div>
                    </div>
                    <a className={`app-store-button`} href={this.state.appStoreInfo.trackViewUrl} target={`_blank`} rel={`noopener`} aria-label={`open`}><FormattedMessage id="shared.open" /></a>
                  </div>
                  }
                  {this.state.isWebPushSupported &&
                  <div className={`notifications`}>
                    <div className={`label`}><FormattedMessage id="shared.allowNotifications" /></div>
                    <div className={`switch-wrapper`}>
                      <Switch
                        checked={this.state.isNotificationsEnabled}
                        onChange={this.handleNotificationsChange}
                        onColor="#F2F4F6"
                        onHandleColor="#10D1C4"
                        handleDiameter={20}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={15}
                        width={40}
                        className="react-switch"
                        id="notifications-switch"
                      />
                    </div>
                  </div>
                  }
                  {this.checkIfPassIsAdded(this.state.pass) &&
                  <a href={`/`} onClick={this.handleRemoveButton.bind(this)} className={`remove-pass`} aria-label={`remove`}><FormattedMessage id="shared.removePass" /></a>
                  }
                  <div className="back-fields">
                    {this.state.pass.data.backFields.map((field, i) => {
                      return (
                        <PassBackField key={i} field={field} pass={this.state.pass} />
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </Container>
        )

      } else {

        if (this.state.showPasses){
          if (this.state.passes && this.state.passes.length > 0) {
            return (
              <Container>
                <div className={`passes`}>
                  <div className={`passes-list`}>
                    {this.state.passes.map((pass, i) => {
                      return (
                          <PassEntry key={i} pass={pass} />
                      )
                    })}
                  </div>
                </div>
              </Container>
            )
          } else {
            return (
              <Container>
                <div className={`passes`}>
                  <div className={`passes-list`}>
                    <div className={`no-data`}>
                      <div className={`warning`}>
                        <div className={`icon`}>
                          <FontAwesomeIcon icon="exclamation-circle" size="4x" />
                        </div>
                        <div className={`text`}>
                          <FormattedMessage id="shared.noData" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Container>
            )
          }

        } else {
          return (
            <Container>
              <div className={`pass`}>
                <div className={`pass-area`}>
                  <div className={`not-found`}>
                    <div className={`warning`}>
                      <div className={`icon`}>
                        <FontAwesomeIcon icon="exclamation-triangle" size="4x" />
                      </div>
                      <div className={`text`}>
                        <FormattedMessage id="shared.notFound" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          )
        }

      }
    }

  }
}

export default injectIntl(PassHandler)
