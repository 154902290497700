import React from "react"
import { injectIntl } from "gatsby-plugin-react-intl"
import PassField from "./pass-field"

class PassHeader extends React.Component {

  componentDidMount() {

  }

  render() {
    return (
      <div className={`pass-header`}>
        <div className={`header`}>
          <div className={`logo`}>
            {this.props.pass.data.logo &&
            <img className={`logo-image`} src={this.props.pass.data.logo} alt="" />
            }
            {this.props.pass.data.logoText &&
            <div className={`logo-text`}>{this.props.pass.data.logoText}</div>
            }
          </div>
        </div>
        <div className={`header-fields`}>
          {this.props.pass.data.headerFields.map((field, i) => {
            if (i < 3) {
              return (
                <PassField key={i} field={field} pass={this.props.pass} />
              )
            } else {
              return ("")
            }
          })}
        </div>
      </div>
    )
  }

}

export default injectIntl(PassHeader)
