import axios from 'axios'
export const APIURL = (process.env.GATSBY_ACTIVE_ENV === 'test') ? "https://push-test.notifica.re" : "https://push.notifica.re";

export const fetchPass = data =>
  axios({
      method: "get",
      url: `${APIURL}/pass/forbarcode/${data.app}/${data.barcode}`,
      headers: {
          "Content-Type": "application/json"
      }
  });

export const fetchPassTemplate = data =>
  axios({
    method: "get",
    url: `${APIURL}/passbook/${data.app}/${data.passbook}`,
    headers: {
      "Content-Type": "application/json"
    }
  });

export const fetchAppStoreInfo = data =>
  axios({
    method: "get",
    url: `https://itunes.apple.com/lookup`,
    params: data,
    headers: {
      "Content-Type": "application/json"
    }
  });

export const fetchSaveLinks = data =>
  axios({
    method: "get",
    url: `${APIURL}/pass/savelinks/${data.serial}`,
    headers: {
      "Content-Type": "application/json"
    }
  });