import React from "react"
import { injectIntl } from "gatsby-plugin-react-intl"
import Moment from 'react-moment';

class PassField extends React.Component {

  componentDidMount() {

  }

  render() {

    let field = this.props.field;

    const valueComponent = () => {
      let value = "XXX";
      if (field.value !== undefined && field.value !== null && field.value !== "") {
        value = field.value;
      }

      if (field.string) {
        return value;
      }

      if (field.number) {
        if (field.currencyCode) {
          let currencySymbol = field.currencyCode;
          if (field.currencyCode === "EUR") {
            currencySymbol = "€";
          } else if (field.currencyCode === "GBP") {
            currencySymbol = "£";
          } else if (field.currencyCode === "USD") {
            currencySymbol = "$";
          }
          return `${currencySymbol} ${value}`
        }

        if (field.numberStyle) {
          return value;
        }

      }

      if (field.date) {
        if (field.isRelative) {
          return <Moment fromNow>{field.value}</Moment>
        } else {
          if (field.dateStyle === "PKDateStyleShort") {
            return <Moment date={field.value} format={`DD-MM-YYYY`} />
          } else if (field.dateStyle === "PKDateStyleMedium" || field.dateStyle === "PKDateStyleLong") {
            return <Moment date={field.value} format={`MMMM Do, YYYY`} />
          } else if (field.dateStyle === "PKDateStyleFull") {
            return <Moment date={field.value} format={`dddd, MMMM Do, YYYY`} />
          }
          if (field.timeStyle === "PKDateStyleShort") {
            return <Moment date={field.value} format={`HH:mm`} />
          } else {
            return <Moment date={field.value} format={`HH:mm:ss`} />
          }
        }
      }
    }

    const textAlignment = () => {
      return field.textAlignment ? field.textAlignment : '';
    }

    const labelColor = () => {
      let bgColor = this.props.pass.data.backgroundColor || 'rgb(255,255,255)';
      return this.props.pass.data.labelColor && this.props.pass.data.backgroundColor !== this.props.pass.data.labelColor ? this.props.pass.data.labelColor : invertHex(rgbToHex(bgColor));
    }

    const foregroundColor = () => {
      let bgColor = this.props.pass.data.backgroundColor || 'rgb(255,255,255)';
      return this.props.pass.data.foregroundColor && this.props.pass.data.backgroundColor !== this.props.pass.data.foregroundColor ? this.props.pass.data.foregroundColor : invertHex(rgbToHex(bgColor));
    }

    const invertHex = (hex) => {
      let h = hex.replace('#','');
      return `#${(Number(`0x1${h}`) ^ 0xFFFFFF).toString(16).substr(1).toUpperCase()}`
    }

    const rgbToHex = (rgb) => {
      let sep = rgb.indexOf(',') > -1 ? ',' : ' ';
      rgb = rgb.substr(4).split(')')[0].split(sep);
      let r = (+rgb[0]).toString(16),
          g = (+rgb[1]).toString(16),
          b = (+rgb[2]).toString(16);
      if (r.length === 1) r = '0' + r;
      if (g.length === 1) g = '0' + g;
      if (b.length === 1) b = '0' + b;
      return '#' + r + g + b;
    }

    return (
      <div className={`field ${textAlignment()}`}>
        <div className={`label`} style={{color: labelColor()}}>
          {field.label}
        </div>
        <div className={`value`} style={{color: foregroundColor()}}>
          {valueComponent()}
        </div>
      </div>
    )
  }

}

export default injectIntl(PassField)
