import React from "react"
import { injectIntl } from "gatsby-plugin-react-intl"
import PassField from "../misc/pass-field"

class PassStyleGeneric extends React.Component {

  componentDidMount() {

  }

  render() {
    let primaryField = null,
      secondaryFields = this.props.pass.data.secondaryFields || [],
      auxiliaryFields = this.props.pass.data.auxiliaryFields || [],
      fields = secondaryFields.concat(auxiliaryFields);

    if (this.props.pass.data.primaryFields && this.props.pass.data.primaryFields.length > 0) {
      primaryField = this.props.pass.data.primaryFields[0];
    }

    let mixedFields = fields.slice(0, 4).map(f => {
      return f;
    })

    let sFields = secondaryFields.slice(0, 4).map(f => {
      return f;
    })

    let aFields = auxiliaryFields.slice(0, 4).map(f => {
      return f;
    })

    const fieldsComponent = () => {
      if (this.props.pass.data.barcode?.format === "PKBarcodeFormatQR") {
        return (
          <div className="mixed-fields">
            {mixedFields.map((field, i) => {
              return (
                <PassField key={i} field={field} pass={this.props.pass} />
              )
            })}
          </div>
        )
      } else {
        return (
          <>
            <div className="secondary-fields">
              {sFields.map((field, i) => {
                return (
                  <PassField key={i} field={field} pass={this.props.pass} />
                )
              })}
            </div>
            <div className="auxiliary-fields">
              {aFields.map((field, i) => {
                return (
                  <PassField key={i} field={field} pass={this.props.pass} />
                )
              })}
            </div>
          </>
        )
      }
    }


    return (
      <div className={`pass-style-generic`}>
        <div className="primary-fields">
          {primaryField &&
          <PassField field={primaryField} pass={this.props.pass} />
          }
        </div>
        <div className="thumbnail">
          {this.props.pass.data.thumbnail &&
          <img src={this.props.pass.data.thumbnail} alt={``} />
          }
        </div>
        {fieldsComponent()}
      </div>
    )
  }

}

export default injectIntl(PassStyleGeneric)
