import React from "react"
import { injectIntl } from "gatsby-plugin-react-intl"

class PassBackField extends React.Component {

  componentDidMount() {

  }

  onBlur(e){
    e.style.height = "";
    e.style.height = e.scrollHeight + "px";
  }

  render() {

    let field = this.props.field;

    const valueComponent = () => {
      let value = "XXX";
      if (field.value !== undefined || field.value !== null || field.value !== "") {
        value = field.value;
      }

      //TODO: recognize address, email, URls and telephone nrs
      return value;
    }


    return (
      <div className={`back-field`}>
        <div className={`label`}>
          {field.label}
        </div>
        <div className={`value`}>
          {valueComponent()}
        </div>
      </div>
    )
  }

}

export default injectIntl(PassBackField)
